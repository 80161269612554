<template>
  <page-header-wrapper>
    <a-card>
      <div class="table-page-search-wrapper">
        <a-form layout="inline" class="table-head-ground">
          <div class="table-layer">
            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker
                @change="onChange"
                :show-time="{ format: 'HH:mm:ss' }"
                format="YYYY-MM-DD HH:mm:ss"
                :disabled-date="disabledDate"
              >
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>
            <a-form-item label="" class="table-head-layout" style="max-width:350px;min-width:300px">
              <a-range-picker
                @change="onChangeTime"
                :show-time="{ format: 'HH:mm:ss' }"
                format="YYYY-MM-DD HH:mm:ss"
                :disabled-date="disabledDate"
                :placeholder="['成功开始时间', '结束开始时间']"
              >
                <a-icon slot="suffixIcon" type="sync" />
              </a-range-picker>
            </a-form-item>
            <jeepay-text-up :placeholder="'转账/商户/渠道订单号'" :msg="searchData.unionOrderId" v-model="searchData.unionOrderId" />
            <a-form-item label="" class="table-head-layout">
              <a-select v-model="searchData.state" placeholder="转账状态" default-value="">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">订单生成</a-select-option>
                <a-select-option value="1">转账中</a-select-option>
                <a-select-option value="2">转账成功</a-select-option>
                <a-select-option value="3">转账失败</a-select-option>
              </a-select>
            </a-form-item>
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryFunc" :loading="btnLoading">搜索</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="() => this.searchData = {}">重置</a-button>
              <a-button
                style="margin-left: 8px"
                type="primary"
                icon="download"
                @click="download(searchData)"
                :loading="loading"
              >转账订单导出</a-button
              >
            </span>
          </div>
        </a-form>
      </div>

      <!-- 列表渲染 -->
      <JeepayTable
        @btnLoadClose="btnLoading=false"
        ref="infoTable"
        :initData="true"
        :reqTableDataFunc="reqTableDataFunc"
        :tableColumns="tableColumns"
        :searchData="searchData"
        rowKey="transferId"
        :tableRowCrossColor="true"
      >
        <template slot="stateSlot" slot-scope="{record}">
          <a-tag
            :key="record.state"
            :color="record.state === 0?'blue':record.state === 1?'orange':record.state === 2?'green':'volcano'"
          >
            {{ record.state === 0?'订单生成':record.state === 1?'转账中':record.state === 2?'转账成功':record.state === 3?'转账失败':record.state === 4?'任务关闭':'未知' }}
          </a-tag>
        </template>
        <template slot="dateSlot" slot-scope="{ record }">
          <div class="order-list">
            <p><span style="color:#729ED5;background:#e7f5f7">创建</span>{{ record.createdAt }}</p>
            <p><span style="color:#56cf56;background:#d8eadf">成功</span>{{ record.successTime }}</p>
          </div>
        </template>
        <template slot="orderSlot" slot-scope="{record}">
          <div class="order-list">
            <p><span style="color:#729ED5;background:#e7f5f7">转账</span>{{ record.transferId }}</p>
            <p style="margin-bottom: 0">
              <span style="color:#56cf56;background:#d8eadf">商户</span>
              <a-tooltip v-if="record.mchOrderNo.length > record.transferId.length" placement="bottom" style="font-weight: normal;">
                <template slot="title">
                  <span>{{ record.mchOrderNo }}</span>
                </template>
                {{ changeStr2ellipsis(record.mchOrderNo, record.transferId.length) }}
              </a-tooltip>
              <span style="font-weight: normal;" v-else>{{ record.mchOrderNo }}</span>
            </p>
            <p v-if="record.channelOrderNo" style="margin-bottom: 0;margin-top: 10px">
              <span style="color:#fff;background:#E09C4D">渠道</span>
              <a-tooltip v-if="record.channelOrderNo.length > record.transferId.length" placement="bottom" style="font-weight: normal;">
                <template slot="title">
                  <span>{{ record.channelOrderNo }}</span>
                </template>
                {{ changeStr2ellipsis(record.channelOrderNo, record.transferId.length) }}
              </a-tooltip>
              <span style="font-weight: normal;" v-else>{{ record.channelOrderNo }}</span>
            </p>
          </div>
        </template>
        <template slot="opSlot" slot-scope="{record}">  <!-- 操作列插槽 -->
          <JeepayTableColumns>
            <a-button type="link" v-if="$access('ENT_TRANSFER_ORDER_VIEW')" @click="detailFunc(record.id)">详情</a-button>
            <a-button type="link" v-if="$access('ENT_PAY_ORDER_REFUND')" style="color: red" @click="openFunc(record, record.transferId)">回调</a-button>
          </JeepayTableColumns>
        </template>
      </JeepayTable>
    </a-card>

    <!-- 订单详情 页面组件  -->
    <TransferOrderDetail ref="transferOrderDetail" />

    <!-- 回调弹出框 -->
    <a-modal :visible="isShow" title="回调" @cancel="cancel">
      <span style="color: red;font-size:16px">确定回调吗?</span>
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" @click="handleOk">确认</a-button>
      </template>
    </a-modal>
  </page-header-wrapper>
</template>
<script>
  import JeepayTable from '@/components/JeepayTable/JeepayTable'
  import JeepayTextUp from '@/components/JeepayTextUp/JeepayTextUp' // 文字上移组件
  import JeepayTableColumns from '@/components/JeepayTable/JeepayTableColumns'
  import TransferOrderDetail from './TransferOrderDetail'
  import { API_URL_TRANSFER_ORDER_LIST, req, transferId, downloadTransfer } from '@/api/manage'
  import moment from 'moment'
  import { formatAmount } from '@/utils/number.js'

  // eslint-disable-next-line no-unused-vars
  const tableColumns = [
    { title: '转账金额', dataIndex: 'amount', customRender: text => formatAmount(text) },
    { key: 'orderNo', title: '订单号', scopedSlots: { customRender: 'orderSlot' }, width: 250 },
    { title: '收款账号', dataIndex: 'accountNo', width: 190 },
    { title: '收款人姓名', dataIndex: 'accountName' },
    { title: '转账备注', dataIndex: 'transferDesc' },
    { title: '状态', scopedSlots: { customRender: 'stateSlot' }, width: 100 },
    { title: '日期', scopedSlots: { customRender: 'dateSlot' } },
    { title: '操作', width: '100px', fixed: 'right', align: 'center', scopedSlots: { customRender: 'opSlot' } }
  ]

  export default {
    name: 'IsvListPage',
    components: { JeepayTable, JeepayTableColumns, JeepayTextUp, TransferOrderDetail },
    data () {
      return {
        loading: false,
        btnLoading: false,
        tableColumns: tableColumns,
        searchData: {},
        createdStart: '', // 选择开始时间
        createdEnd: '', // 选择结束时间
        transferId: '',
        isShow: false
      }
    },
    methods: {
       // 导出
    download () {
      var createdEndDate = new Date(this.searchData.createdStart)
      var successEndDate = new Date(this.searchData.successStart)
      if (!(this.searchData.createdStart && this.searchData.createdEnd) && !(this.searchData.successStart && this.searchData.successEnd)) {
        this.$message.error('订单导出时间选择不能为空')
      } else if (!(moment(this.searchData.createdEnd, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') <= moment(createdEndDate.setMonth(createdEndDate.getMonth() + 1)).format('YYYY-MM-DD'))) {
        this.$message.error('时间选择为一个月内')
      } else if (!(moment(this.searchData.successEnd, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') <= moment(successEndDate.setMonth(successEndDate.getMonth() + 1)).format('YYYY-MM-DD'))) {
        this.$message.error('时间选择为一个月内')
      } else {
        this.loading = true
        const iPage = this.$refs.infoTable.iPage
        const pageSize = iPage.pageSize
        const pageNumber = iPage.pageNumber
        downloadTransfer(pageNumber, pageSize, this.searchData.createdStart, this.searchData.createdEnd, this.searchData.successStart,
        this.searchData.successEnd, this.searchData.unionOrderId, this.searchData.state).then(res => {
        this.loading = false
        if (res.code !== 9999) {
          this.downloadFile(res)
        } else {
          this.$message.error(res.msg) // 显示异常信息
        }
        }).catch(() => {
          this.loading = false
        })
      }
    },
    downloadFile (data) {
      // 文件导出
      if (!data) {
        return
      }
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', 'TransferOrder.xlsx')

      document.body.appendChild(link)
      link.click()
    },
      queryFunc () {
        this.btnLoading = true
        this.$refs.infoTable.refTable(true)
      },
      // 请求table接口数据
      reqTableDataFunc: (params) => {
        return req.list(API_URL_TRANSFER_ORDER_LIST, params)
      },
      searchFunc: function () { // 点击【查询】按钮点击事件
        this.$refs.infoTable.refTable(true)
      },
      detailFunc: function (id) {
        this.$refs.transferOrderDetail.show(id)
      },
      moment,
      onChange (date, dateString) {
        this.searchData.createdStart = dateString[0] // 开始时间
        this.searchData.createdEnd = dateString[1] // 结束时间
      },
      onChangeTime (date, dateString) {
      this.searchData.successStart = dateString[0] // 开始时间
      this.searchData.successEnd = dateString[1] // 结束时间
    },
      disabledDate (current) { // 今日之后日期不可选
        return current && current > moment().endOf('day')
      },
      changeStr2ellipsis (orderNo, baseLength) {
        const halfLengh = parseInt(baseLength / 2)
        return orderNo.substring(0, halfLengh - 1) + '...' + orderNo.substring(orderNo.length - halfLengh, orderNo.length)
      },
      // 打开回调弹出框
      openFunc (record, transferId) {
        this.isShow = true
        this.transferId = transferId
      },
      handleOk () {
        transferId(this.transferId).then(res => {
        this.$message.success(res)
        this.isShow = false
      })
      },
      // 回调弹出框取消按钮
      handleCancel () {
        this.isShow = false
      },
      cancel () {
        this.isShow = false
      }
    }
  }
</script>
<style lang="less" scoped>
.order-list {
  -webkit-text-size-adjust:none;
  font-size: 12px;
  display: flex;
  flex-direction: column;

  p {
    white-space:nowrap;
    span {
      display: inline-block;
      font-weight: 800;
      height: 16px;
      line-height: 16px;
      width: 35px;
      border-radius: 5px;
      text-align: center;
      margin-right: 2px;
    }
  }
}
</style>
